import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { CalloutBanner, KMCurve, TwoColumnLayout, NCCNCallout, Stat2, PageSeparator, Accordion, NotationBlock, Image } from '../../components';
import KMCurveImage from '../../assets/images/kmcurve.svg';
import KMCurveImageMobile from '../../assets/images/kmcurve-mobile.svg';
import PinchToZoom from '../../assets/images/pinch-to-zoom.svg';
import graphicWeliregEverolimusImage from '../../assets/images/efficacy-graphic-welireg-everolimus-v2.svg';
import graphicWeliregEverolimusMobileImage from '../../assets/images/efficacy-graphic-welireg-everolimus-mobile-v2.svg';
import efficacyExpandedDetails from '../../assets/images/efficacy-expanded-details.svg';
import efficacyExpandedDetailsMobile from '../../assets/images/efficacy-expanded-details-mobile.svg';
import StudyDesignImage from '../../assets/images/study-design.svg';
import StudyDesignImageMobile from '../../assets/images/study-design-mobile.svg';
import StudyDesignStatsImage from '../../assets/images/study-design-stats.svg';
import StudyDesignStatsImageMobile from '../../assets/images/study-design-stats-mobile.svg';
import { breakpoints, respondTo } from '../../styles/shared/breakpoints';
import * as colors from '../../styles/shared/colors';
import {linkOffsetHash} from '../../util/jumpTo';
import { useEffect } from 'react';

// SEO INFORMATION
const pageMeta = {
  title: 'LITESPARK-005 | Efficacy Data for WELIREG™ (belzutifan)',
  keywords: 'litespark-005',
  description:  'Health care professionals can review efficacy data and the study design from LITESPARK-005 for WELIREG™ (belzutifan).',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/efficacy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/efficacy/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","manufacturer":"Merck"}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/efficacy/","@type":"MedicalTrial","name":"LITESPARK-005","description":"WELIREG demonstrated superior PFS vs everolimus in patients with advanced RCC following treatment with both anti⁠–PD⁠-⁠1⁠/⁠L1 and VEGF⁠-⁠TKI therapies","trialDesign":"The only Phase 3 trial in advanced RCC to specifically evaluate patients who progressed following treatment with both anti–PD⁠-⁠1/L1 and VEGF-TKI therapies"}`
  ]
}

const EfficacyKMCurve = styled(KMCurve)`
  [class*="_ImageContainer-"] {
    ${respondTo.sm`
      width: auto;
      max-width: 100%;  
    `}
  }
`;

const SingleColumnLayout = styled(TwoColumnLayout)`
  picture {
    max-width: 838px;
    margin: 0 auto;    
  }

  picture img {
    width: 100%;
    height: auto;
  }
  
  [class*="_TwoColumnLayoutWrapper-"] {
    display: flex;
  }
`;

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const StudyStatsImage = styled(Image)`
  margin-top: 24px;
  text-align: center;
  
  [class*="_GraphTitle-"] {
    text-align: center;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 16px;
  }
  
  [class*="_GraphTitle-"] {
    text-align: left;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 16px;
    width: 100%;
    max-width: none;
    
    ${respondTo.sm`
      text-align: center;
      font-size: 24px;
      line-height: 1.3;
    `}
  }
`;

const StudyStatsBottom = styled.h3`
  margin-top: 16px;
  text-align: left;
  color: ${colors.brandBlueDark};
  font-size: 20px;
  line-height: 1.2;

  ${respondTo.sm`
    text-align: center;
    font-size: 24px;
    line-height: 1.3;
  `}
`;

const AnchorHidden = styled.div`
    height: 10px;
    visibility: hidden;
`;

const KMCurveStyledUL = styled.ul`
  list-style-type: disc;
  color: #444;
  line-height: 22px;

  li {
    font-weight: 400;
    
  }

  li::marker {
    color: red;
  }

  .styled-li {
    font-weight: 700;
  }
`;

const anchorLinkItems = ['PFS', 'OS', 'ORR', 'Study Design', 'Guidelines'];

const KMCurveAdditionalInfo = <>
  <KMCurveStyledUL>
    <li className='styled-li'>25% reduced risk of disease progression or death shown with WELIREG vs everolimus (HR=0.75<sup>a</sup>; 95% CI, 0.63–0.90; <em>P</em>=0.0008)<sup>b</sup></li>
    <li>Events observed: 257/374 (69%) with WELIREG vs 262/372 (70%) with everolimus</li>
    <li>Median PFS<sup>c</sup>: 5.6 months (95% CI, 3.9–7.0) with WELIREG vs 5.6 months (95% CI, 4.8–5.8) with everolimus</li>
    <li>OS results were immature. At the time of the subsequent prespecified analysis, 59% of the patients had died in the randomized population</li>
  </KMCurveStyledUL>
</>;

const KMCurveNotationData = {
  footnotes: [
    {
      label: 'a',
      text: 'Based on the stratified Cox proportional hazard model.',
    },
    {
      label: 'b',
      text: <>One-sided <i>P</i>-value based on stratified log-rank test compared with the significance boundary of 0.0021.</>,
    },
    {
      label: 'c',
      text: 'From product-limit (Kaplan-Meier) method for censored data.',
    },
  ],
  definitions: <>CI = confidence interval; HR = hazard ratio; OS = overall survival; PD&#8288;-&#8288;1/L1 = programmed death receptor-1 (PD&#8288;-&#8288;1)/programmed death-ligand 1 (PD&#8288;-&#8288;L1); PFS = progression-free survival; VEGF-TKI = vascular endothelial growth factor tyrosine kinase inhibitor.</>,
};

const NCCNDefinitionsArray = [
  <><sup className="footnote-super">f</sup>This regimen is for patients that have received a PD-1 or PD-L1 inhibitor and a VEGF-TKI.</>,
  'Category 2A = Based on lower-level evidence, there is uniform NCCN consensus that the intervention is appropriate.',
  'NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
  <><div className='mg-top'>NCCN = National Comprehensive Cancer Network® (NCCN®)</div></>,
];

const NCCNReferences = <>
  <strong>Reference: 1.</strong> Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 5, 2024. To view the most recent and complete version of the guideline, go online to NCCN.org.
</>;

const calloutBanner = {
  title: <>WELIREG may be considered as early as the second line for your appropriate patients with advanced RCC following treatment with both anti&#8288;–&#8288;PD&#8288;-&#8288;1&#8288;/&#8288;L1 and VEGF&#8288;-&#8288;TKI therapies</>,
  //continualInvertedButtons: true,
  bcbContinual: true,
  links: [
    {
      label: 'See Treatment Sequencing',
      url: '/advanced-renal-cell-carcinoma/treatment-considerations',
    },
  ],
};

const orrVsEverolimusTwoColumnProps = {
  title: <>WELIREG demonstrated superior ORR vs everolimus in advanced RCC following treatment with both anti&#8288;–&#8288;PD&#8288;-&#8288;1&#8288;/&#8288;L1 and VEGF&#8288;-&#8288;TKI therapies</>,
  subtitle: <>Patients who received WELIREG achieved an ORR of 22% vs 4% of patients who received everolimus (<strong><em>P</em></strong>&lt;0.0001)<sup>d</sup></>,
};

const statTitle = <><b>30<sup>%</sup></b></>;
const statDescription = 'of patients who responded (25/82) with WELIREG had a duration of response that lasted ≥12 months.';

const statFooter = {
  footnotes: [
    { label: 'd', text: <>One-sided <i>P</i>-value based on stratified Miettinen and Nurminen (M&N) method.</> },
    { label: 'e', text: 'ORR measured by blinded independent central review using RECIST v1.1.'},
     ],
    definitions: 'ORR = objective response rate; CR = complete response; PR = partial response.',
};

const accordionFootnotes = [
  <>ECOGS PS = Eastern Cooperative Oncology Group performance status; IMDC = International Metastatic Database Consortium; PD&#8288;-&#8288;1/L1 = programmed death receptor&#8288;-&#8288;1 (PD&#8288;-&#8288;1)/programmed death&#8288;-&#8288;ligand 1 (PD&#8288;-&#8288;L1); RCC = renal cell carcinoma; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors; VEGF = vascular endothelial growth factor; VEGF&#8288;-&#8288;TKI = vascular endothelial growth factor tyrosine kinase inhibitor.</>,
];


const IndexPage = ({location}) => {

  useEffect(() => {
    if(location.hash)
      linkOffsetHash(location.hash)

  }, [location]);

      return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/advanced-renal-cell-carcinoma/efficacy/" />
      </PageSeo>

      <PageTitle anchorLinksData={anchorLinkItems}>
        Efficacy Data for WELIREG™ (belzutifan)
      </PageTitle>

      <StandardSection>
        <PageSeparator title='Clinical trial results from LITESPARK-005' topPadding='20px' topPaddingMobile='28px' bottomPadding='48px' bottomBorder={true} />
        <PageSeparator topPadding='48px' bottomPadding='0px' />
      </StandardSection>

      <div id='PFS'>
        <AnchorHidden id='OS'/>
        <StandardSection>
            <EfficacyKMCurve
              title="WELIREG demonstrated superior PFS vs everolimus in patients with advanced RCC following treatment with both anti&#8288;–&#8288;PD&#8288;-&#8288;1&#8288;/&#8288;L1 and VEGF&#8288;-&#8288;TKI therapies"
              additionalInformation={KMCurveAdditionalInfo}
              graphTitle="Kaplan-Meier Estimates of PFS in LITESPARK-005"
              zoomImage={PinchToZoom}
              image={KMCurveImage}
              imageMobile={KMCurveImageMobile}
              notationData={KMCurveNotationData}
              imageAlt='Kaplan-Meier Estimates of PFS in LITESPARK-005'
            />
        </StandardSection>
      </div>

      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
        <PageSeparator topPadding='0px' bottomPadding='48px' bottomPaddingMobile='32px' topBorder={true} />
      </StandardSection>

      <StandardSection>
        <div id='ORR'> 
          <SingleColumnLayout {...orrVsEverolimusTwoColumnProps}> 
            <picture>
              <source media={`(min-width: ${breakpoints.sm}px)`} srcSet={graphicWeliregEverolimusImage} />
              <source srcSet={graphicWeliregEverolimusMobileImage} />
              <img src={graphicWeliregEverolimusImage} alt='LEFT: Patients With Advanced RCC Who Received WELIREGTM (belzutifan) Achieved an Objective Response Rate of 22% RIGHT: Patients With Advanced RCC Who Received Everolimus Achieved an Objective Response Rate of 4%' />
            </picture>
          </SingleColumnLayout>
        </div>
      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='24px' topPaddingMobile='32px' />
      </StandardSection>

      <StandardSection>

          <Stat2 title={statTitle} description={statDescription} footer={statFooter} />

      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' bottomBorder={true} />
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
      </StandardSection>
      <div id='Study-Design'>
      <StandardSection>
          <Image
            title="LITESPARK-005 study design: The only Phase 3 trial in advanced RCC to specifically evaluate patients who progressed following treatment with both anti–PD&#8288;-&#8288;1/L1 and VEGF-TKI therapies"
            subtext="WELIREG was studied in an open-label, randomized, Phase 3 trial vs everolimus"
            imgPath={StudyDesignImage}
            mobImgPath={StudyDesignImageMobile}
            imgAlt="LITESPARK-005 Study Design: Information on Key Eligibility Criteria"
          />
          <StudyStatsImage
            maxWidth='100%'
            graphTitle="Distribution of prior anti–PD-1/L1 and VEGF-TKI therapies that patients received in LITESPARK-005"
            imgPath={StudyDesignStatsImage}
            mobImgPath={StudyDesignStatsImageMobile}
            imgAlt="Distribution of Prior Anti-PD-1/L1 and VEGF-TKI Therapies in LITESPARK-005"
          />
          <StudyStatsBottom>Of all evaluated patients, <strong>49% received 2 to 3 prior VEGF receptor–targeted therapies</strong></StudyStatsBottom>
      </StandardSection>
      </div>

      <StandardSection>
        <PageSeparator topPadding='24px' topPaddingMobile='32px' />
      </StandardSection>

      <StandardSection>


          <Accordion title='Additional study design and study population details'>
            <Image imgAlt='LITESPARK-005: Additional Details Including Stratification Factors, Main Efficacy Outcomes, Additional Outcomes, and Study Population Characteristics' imgPath={efficacyExpandedDetails} mobImgPath={efficacyExpandedDetailsMobile} />
          </Accordion>

      </StandardSection>

      <StandardSection>
        <NotationBlock definitions={accordionFootnotes} />
      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' bottomBorder={true} />
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
      </StandardSection>

      <div id='Guidelines'>
      <StandardSection>
          <NCCNCallout 
              subtitle="For appropriate patients with advanced clear cell RCC following treatment with both anti&#8288;–&#8288;PD&#8288;-&#8288;1/L1 and VEGF&#8288;-&#8288;TKI therapies,"
              title="Belzutifan (WELIREG) is an NCCN Category 2A recommended subsequent therapy option according to the National Comprehensive Cancer Network® (NCCN®)"
              nccnText={<div className='vertical-center'>NCCN <span className='block-mobile'>RECOMMENDED OPTION</span></div>}
              nccnDefinition={NCCNDefinitionsArray}
              nccnReference={NCCNReferences}
          >
              Belzutifan (WELIREG) is the only <strong>HIF&#8288;-&#8288;2α inhibitor included as an other<br className='tmp' /> recommended subsequent therapy option (NCCN Category 2A)</strong> for patients with advanced clear cell RCC following prior PD&#8288;-&#8288;1 or PD&#8288;-&#8288;L1 inhibitor and VEGF&#8288;-&#8288;TKI therapies.<sup>1,f</sup>
          </NCCNCallout>
      </StandardSection>
      </div>

      <StandardSection>
        <PageSeparator topPadding='48px' topPaddingMobile='32px' />
      </StandardSection>

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default IndexPage;
